
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/videoInspection";

@Options({
  components: {},
})
export default class extends Vue {
  typename = "";
  deviceName = "";
  typePicker = false;
  devicePicker = false;
  flag = false;
  isLoad = false;
  typePickerscolumns = [
    { id: 1, name: "安全巡检" },
    { id: 2, name: "质量巡检" },
  ];
  devicePickerscolumns: any[] = [];

  goBack() {
    this.$router.go(-1);
  }

  submitform: any = {
    title: "",
    type: "",
    deviceId: "",
    description: "",
  };
  changePersonName = "";

  mounted() {
    this.initareaIds();
  }

  initareaIds() {
    api
      .listUsableDevice({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.devicePickerscolumns = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }
  typePickersonConfirm(val: any) {
    this.submitform.type = val.id;
    this.typename = val.name;
    this.typePicker = false;
  }

  devicePickersonConfirm(val: any) {
    this.submitform.deviceId = val.deviceId;
    this.deviceName = val.deviceName;
    this.devicePicker = false;
  }

  onSubmit() {
    const projectId = this.$store.state.project.projectinfo.projectId;
    console.log(projectId);

    const data = {
      projectId,
      ...this.submitform,
    };
    this.isLoad = true;

    api
      .startVideoCheck(data)
      .then((res: any) => {
        if (res.code == 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              this.$router.push({
                path: "/app/videoInspectionEnd",
                query: { id: res.data },
              });
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      })
      .catch((err) => {
        // console.log('err')
      })
      .finally(() => {
        this.isLoad = false;
      });
  }
}
